import { Box, Chip, CircularProgress, SxProps, TableCell, TableRow, Theme, Tooltip } from '@mui/material'
import { formatCurrency, formatDate, getNumber, getTooltip } from '../../../../../../../utility/helper'
import { FC, useEffect, useMemo, useState } from 'react'
import { ICustIneligible, IHeader } from '../../../..';
import { IARTransaction } from '../../../../../../../interfaces';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { reportsAPI } from '../../../../../../../service/api';
import axiosInstance from '../../../../../../../service/axiosInstance';
import { AMERICAN_DATE_FORMAT, GET, codesWithNoDetails, customerLevelCodes } from '../../../../../../../utility/constants';
import { getEligibleAmount, getRowKey, ICustomerViewTableProps } from '..';
import styles from './styles';

export interface ICustomerRowProps extends ICustomerViewTableProps {
  searched          : boolean;
  closeSearch       : boolean;
  index             : number;
  lastRowElementRef : (node: any) => void;
  tableRowStyle     : SxProps<Theme>;
  row: {
    [x: string]: string | number;
    [x: number]: string | number;
  };
  array: {
    [x: string]: string | number;
    [x: number]: string | number;
  }[];
}

const CustomerRow: FC<ICustomerRowProps> = (props) => {
  const {
    searched, index,
    row, array, headers,
    lastRowElementRef,
    closeSearch,
    queryParams,
    ineligibleCodes,
    custIdsWithDetails,
    currencyCode,
    exchangeRate,
    tableRowStyle,
    isUltimateParent,
    reportCodes,
    arCollateralId
  }                                               = props;
  const [open, setOpen]                           = useState<boolean>(false);
  const [isDetailLoading, setIsDetailLoading]     = useState<boolean>(false);
  const [custDetails, setCustDetails]             = useState<{[key: string] : IARTransaction[]}[]>([]);

  /**
   * This useEffect is used to either expand or minimize the row when there's a change on closeSearch props.
   */
  useEffect(() => {
    if (open) setOpen(false);
  }, [closeSearch]);

  const availableCodes = useMemo(() => {
    return reportCodes?.filter(code => code.id !== 'Gross AR').map(code => code.id);
  }, [reportCodes])

  /**
   * This memoized value is used to determine if a customer has details to show.
   */
  const hasDetails = useMemo(() => {
    if (isUltimateParent) {
      const rowCodes: string[] = Object.keys(row).filter(key => availableCodes?.includes(key));
      
      return rowCodes?.some(code => {
        if (code === 'DLQ') {
          return true;
        } else if (codesWithNoDetails.includes(code)) {
          return false;
        } else {
          const ids = custIdsWithDetails[code];
          return ids?.includes(row.arCustomerId as number);
        }
      })
    } else {
      const rowKeysToRemove = [
        'arCustomerId',
        'arCollateralId',
        'Gross AR',
        'eligibleAR',
        'custSrcId',
        'custName',
        'ineligibleAmount',
        'isUpcParentCustomer',
        'currencyId'
      ];
      const rowAmountTypes: string[] = Object.keys(row).filter(key => !rowKeysToRemove.includes(key));
  
      const mergedCodes = ineligibleCodes.reduce((result, item) => {
        const [key, value] = Object.entries(item)[0];
        return { ...result, [key]: value };
      }, {});
  
      return rowAmountTypes.some(amountType => {
        const code = mergedCodes[amountType];
  
        if (customerLevelCodes.includes(code)) {
          if (code === 'CONTRA') return false;
  
          const ids = custIdsWithDetails[code];
          return ids?.includes(row.arCustomerId as number);
        } else {
          return true;
        }
      });
    }
  }, [row, custIdsWithDetails, ineligibleCodes, customerLevelCodes, availableCodes])

  /**
   * This function handle closes the row if its open, and trigger the loading state to true if its close.
   */
  const openRow = async () => {
    if (open) {
      setOpen(false);
    } else if (custDetails.length) {
      setOpen(true);
    } else {
      setIsDetailLoading(true);
      if (isUltimateParent) {
        await getUpcDetailsPerCode();
      } else {
        await getDetailsPerCode();
      }
      setIsDetailLoading(false);
      setOpen(true);
    }
  }

  /**
   * This function gets the details per code asynchronously.
   */
  const getDetailsPerCode = async () => {
    const promises = ineligibleCodes.map(async (ineligibleCode) => {
      const { borrowerId, bbPeriodId, arCollateralId } = queryParams;
      const code = Object.values(ineligibleCode)[0];

      if (customerLevelCodes.includes(code)) {
        if (code === 'CONTRA') return ({ code, details: [] });
        
        const ids = custIdsWithDetails[code];
        
        if (ids?.includes(row.arCustomerId as number)) {
          const details =
            await getDetails(
              borrowerId,
              bbPeriodId,
              arCollateralId,
              row.arCustomerId as number,
              code
            );

          return { code, details }
        } else {
          const details: IARTransaction[] = [];
          return { code, details }
        }
      } else {
        const details =
          await getDetails(
            borrowerId,
            bbPeriodId,
            arCollateralId,
            row.arCustomerId as number,
            code
          );

        return { code, details }
      }
    });

    Promise.all(promises)
      .then((results) => {
        const custDetails: {[key: string]: IARTransaction[]}[] = [];
        results.forEach(({ code, details }) => {
          custDetails.push({[code]: details});
        });
        setCustDetails(custDetails);
      });
  };

  const getUpcDetailsPerCode = async () => {
    const { borrowerId, endDate } = queryParams;
    
    const promises = availableCodes?.filter(code => !codesWithNoDetails.includes(code as string))
    .map(async (code) => {
      if (code === 'DLQ') {
        const details = await getUpcDetailsRequest(
          borrowerId,
          arCollateralId as number,
          endDate,
          row.arCustomerId as number,
          code,
          Boolean(row.isParentCustomer)
        )

        return { code, details }
      } else {
        const ids = custIdsWithDetails[code];

        if (ids?.includes(row.arCustomerId as number)) {
          const details = await getUpcDetailsRequest(
            borrowerId,
            arCollateralId as number,
            endDate,
            row.arCustomerId as number,
            code as string,
            Boolean(row.isParentCustomer)
          )

          return { code, details }
        } else {
          const details: IARTransaction[] = [];
          return { code, details }
        }
      }
    })

    const results = await Promise.all(promises as Promise<{code: keyof ICustIneligible; details: IARTransaction[]}>[]);
    const custDetails = results
      .reduce((acc: {[key: string]: IARTransaction[]}[], item: {code: keyof ICustIneligible; details: IARTransaction[]}) => {
        const { code, details } = item;
        acc.push({[code]: details})
        return acc;
      }, []);
    setCustDetails(custDetails);
  }

  /**
   * This function gets the details of the Customer of the AR Ineligible Report.
   * @param borrowerId The Borrower ID of the AR Ineligible Report.
   * @param bbPeriodId The BB Period ID of the AR Ineligible Report.
   * @param arCollateralId The AR Collateral ID of the AR Ineligible Report.
   * @param arCustomerId The Customer ID of the AR Ineligible Report Row.
   * @param ineligibleCode The Ineligible Code to be used.
   * @returns An array of the transaction details of a customer.
   */
  const getDetails = async (borrowerId: number, bbPeriodId: number, arCollateralId: number, arCustomerId: number, ineligibleCode: string) => {
    try {
      const response = await axiosInstance.request({
        url: reportsAPI.arIneligibleReport.GET_INELIGIBLE_DETAILS,
        method: GET,
        params: { borrowerId, arCollateralId, bbPeriodId, arCustomerId, ineligibleCode },
      });
      return response.data as IARTransaction[]
    } catch (error) {
      console.log('INELIGIBLE DETAILS ERROR: ', error);
      return [];
    }
  }

  /**
   * Retrieves UPC ineligible details through an API request.
   * @param parentClientId The ID of the parent client.
   * @param endDate The end date for the report in the format 'YYYY-MM-DD'.
   * @param arCustomerId The ID of the AR customer.
   * @param ineligibleCode The code indicating the type of ineligibility.
   * @param isUpcParentCustomer A boolean indicating whether the parent customer is a UPC parent.
   */
  const getUpcDetailsRequest = async (parentClientId: number, arCollateralId: number, endDate: string, arCustomerId: number, ineligibleCode: string, isUpcParentCustomer: boolean) => {
    try {
      const response = await axiosInstance.request({
        url: reportsAPI.arIneligibleReport.GET_UPC_INELIGIBLE_DETAILS,
        method: GET,
        params: {
          parentClientId,
          arCollateralId,
          asOfDate: endDate,
          arCustomerId,
          ineligibleCode,
          isUpcParentCustomer
        },
      });
      return response.data as IARTransaction[]
    } catch (error) {
      console.log('INELIGIBLE DETAILS ERROR: ', error);
      return [];
    }
  }
  
  /**
   * This function dynamically sets the Icon for the columns Open/Close icon
   * 
   * @returns An Icon component.
   */
  const getIcon = () => {
    if (open) {
      return <ArrowDropUpIcon fontSize='small' sx={styles.collapseIcon} />
    } else if (isDetailLoading) {
      return <CircularProgress size={15}/>
    } else {
      return <ArrowDropDownIcon fontSize='small' sx={styles.collapseIcon} />
    }
  }

  /**
   * This function gets the style of the row spacer for the first index row.
   * @param index The index of the column.
   * @returns A style for a spacer column or null.
   */
  const getSpacer = (index: number) => {
    if (index) return null;
    else return styles.tableRowSpacer;
  }

  /**
   * This function gets the equivalent ineligible code of the amount type.
   * @param amountType The amount type of the header.
   * @returns The equivalent ineligible code of the amount type.
   */
  const getCodeByAmountType = (amountType: string) => {
    if (isUltimateParent) {
      return amountType;
    } else {
      const matchingIneligibleCode = ineligibleCodes.find(ineligibleCode => Object.keys(ineligibleCode)[0] === amountType);
      if (matchingIneligibleCode) return Object.values(matchingIneligibleCode)[0];
    }
  }

  /**
   * This gets the parent chip according to row as a parent customer
   * @returns A Chip component indicating Parent Customer Status.
   */
  const getParentChip = () => {
    if (isUltimateParent) {
      return (Boolean(props.row.isParentCustomer) &&
        <Chip tabIndex={0} label='Parent' size='small' sx={styles.parentIdentifierChip} />)
    } else {
      return (props.parentIds.includes(getNumber(props.row.arCustomerId)) &&
        <Chip tabIndex={0} label='Parent' size='small' sx={styles.parentIdentifierChip} />)
    }
  }

  return (
    <>
    <TableRow
      hover
      key={`${getRowKey(row.custSrcId)} - ${getRowKey(row.custName)}`}
      ref={!searched && array.length === index + 1 ? lastRowElementRef : undefined}
      sx={tableRowStyle}
      onClick={hasDetails ? () => openRow() : undefined}
    >
      <TableCell tabIndex={0} sx={{...styles.tableActionBody, ...getSpacer(index)}}>
        <Box sx={styles.actionIconContainer}>
          {hasDetails && getIcon()}
        </Box>
      </TableCell>
      {headers.map((header: IHeader) => {
        let amount = row[header.id];
        if ((header.id === 'Gross AR' || header.id === 'eligibleAR') && isUltimateParent) {
          amount = -row[header.id];
        }
        
        if (header.id === 'custName') {
          return (
          <TableCell tabIndex={0} key={header.id} sx={{...styles.tableTextBody, ...styles.tableTextBodyLeft, ...getSpacer(index)}}>
            <Box sx={styles.custNameWithParent}>
              <Box sx={styles.custNameContainer}>
                {`${row.custName}`.length > 25 ?
                    <Tooltip title={`${row.custName}`} placement='bottom-start'>
                      <Box sx={styles.tableTextCustomer}>{row.custName}</Box>
                    </Tooltip> : row.custName}
              </Box>
              {getParentChip()}
            </Box>
          </TableCell>)
        } else if (header.id === 'custSrcId') {
          return (
            <TableCell tabIndex={0} key={header.id} sx={{...styles.tableTextBody, ...styles.tableTextCustSrcId, ...getSpacer(index)}}>
              {`${row.custSrcId}`.length > 25 ?
                  <Tooltip title={`${row.custSrcId}`} placement='bottom-start'>
                    <Box sx={styles.tableTextCustomer}>
                      {row.custSrcId}
                    </Box>
                  </Tooltip> : row.custSrcId}
            </TableCell>
          )
        } else {
          return (
          <TableCell tabIndex={0} key={header.id} sx={{...styles.tableTextBody, ...styles.tableTextBodyNumber, ...getSpacer(index)}}>
            {getEligibleAmount(amount, currencyCode, exchangeRate)}
          </TableCell>)
        }
      })}
    </TableRow>
    {open && 
    <>
    <TableRow
      key={`${row.arCustomerId} - detail headers`}
      sx={tableRowStyle}
    >
      <TableCell sx={{...styles.detailsTableHeader}}></TableCell>
      {headers.map(header => {
        if (header.id === 'custSrcId') {
          return (
            <TableCell
              key={header.id}
              sx={{...styles.detailsTableHeader}}
            >
              Document #
            </TableCell>
          )
        } else if (header.id === 'custName') {
          return (
            <TableCell
              key={header.id}
              sx={{...styles.detailsTableHeader}}
            >
              <Box sx={styles.detailDatesContainer}>
                <TableCell
                  onMouseEnter={getTooltip}
                  sx={{...styles.detailsTableText, ...styles.detailsDate}}
                >
                  Invoice Date
                </TableCell>
                <TableCell
                  onMouseEnter={getTooltip}
                  sx={{...styles.detailsTableText, ...styles.detailsDate, ...styles.detailsDueDays}}
                >
                  DPID
                </TableCell>
                <TableCell
                  onMouseEnter={getTooltip}
                  sx={{...styles.detailsTableText, ...styles.detailsDate}}
                >
                  Due Date
                </TableCell>
                <TableCell
                  onMouseEnter={getTooltip}
                  sx={{...styles.detailsTableText, ...styles.detailsDate, ...styles.detailsDueDays}}
                >
                  DPDD
                </TableCell>
              </Box>
            </TableCell>
          )
        } else {
          return (
            <TableCell key={header.id} sx={{...styles.detailsTableHeader}}></TableCell>
          )
        }
      })}
    </TableRow>

    {custDetails.map(detail => {
      const transactions: IARTransaction[] = Object.values(detail)[0];

      return transactions.map(transaction => (
        <TableRow
          key={transaction.recordId}
          sx={tableRowStyle}
        >
          <TableCell sx={{...styles.detailsTableText}}></TableCell>
          {headers.map(header => {
            if (header.id === 'custSrcId') {
              return (
                <TableCell
                  key={header.id}
                  sx={{...styles.detailsTableText}}
                >
                  {transaction.arTrxNo}
                </TableCell>
              )
            } else if (header.id === 'custName') {
              return (
                <TableCell
                  key={header.id}
                  sx={{...styles.detailsTableText}}
                >
                  <Box sx={styles.detailDatesContainer}>
                    <TableCell
                      onMouseEnter={getTooltip}
                      sx={{...styles.detailsTableText, ...styles.detailsDate}}
                    >
                      {formatDate(transaction.arTrxDate, AMERICAN_DATE_FORMAT)}
                    </TableCell>
                    <TableCell
                      onMouseEnter={getTooltip}
                      sx={{...styles.detailsTableText, ...styles.detailsDate, ...styles.detailsDueDays}}
                    >
                      {transaction.calcDaysAgedInvoiceDate}
                    </TableCell>
                    <TableCell
                      onMouseEnter={getTooltip}
                      sx={{...styles.detailsTableText, ...styles.detailsDate}}
                    >
                      {transaction.arTrxDueDate ? formatDate(transaction.arTrxDueDate, AMERICAN_DATE_FORMAT) : null}
                    </TableCell>
                    <TableCell
                      onMouseEnter={getTooltip}
                      sx={{...styles.detailsTableText, ...styles.detailsDate, ...styles.detailsDueDays}}
                    >
                      {transaction.arTrxDueDate ? transaction.calcDaysAgedDueDate : null}
                    </TableCell>
                  </Box>
                </TableCell>
              )
            } else {
              return (
                <TableCell key={header.id} sx={{...styles.detailsTableText, ...styles.detailsTableTextRight}}>
                  {Object.keys(detail)[0] === getCodeByAmountType(header.id as string) ?
                    formatCurrency(transaction.arTrxAmt, currencyCode, exchangeRate) : 
                    null}
                </TableCell>
              )
            }
          })}
        </TableRow>
      ))
    })}
    </>}
    </>
  )
}

export default CustomerRow;
const styles = {
  body: {
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
  },
  hidden: {
    display: 'none'
  },
  reportHeaderSubContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  totalAmountText: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    marginRight: '6px',
    fontWeight: 'bold',
  },
  totalAmount: {
    fontSize: '14px',
  },
  tableForGrandTotal: {
    backgroundColor: '#EAEAEA',
    marginY: '24px',
  },
  tableFooterText: {
    backgroundColor: 'inherit',
    color: '#000',
    paddingTop: '1rem',
    paddingBottom: '0.8rem',
    fontSize: '15px',
    paddingX: '0.1rem',
    fontWeight: 'bold',
  },
  widthActionButton: {
    minWidth: '2rem',
    maxWidth: '2rem',
  },
  widthCustomerId: {
    minWidth: '10rem',
    maxWidth: '10rem',
  },
  widthCustomerName: {
    minWidth: '10rem',
    maxWidth: '10rem',
  },
  widthBuckets: {
    minWidth: '5rem',
    maxWidth: '5rem',
  },
  tableCellNumber: {
    paddingRight: '1rem',
  },
};

export default styles;
import { Box, Button, CircularProgress, Typography, useMediaQuery, useTheme } from "@mui/material";
import styles from "./styles";
import MenuButton from "../../../../components/common/menu-button";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { reportsAPI } from "../../../../service/api";
import { checkFileNameIfExisting, formatDate, getFileName, toCamelCase } from "../../../../utility/helper";
import { APAgingReportContext } from "../../../../context/apAgingReportContext";
import { IAPAgingReportContext, IAPAgingReportExportPayload, IBucketType, IUPCAPAgingReportExportPayload } from "../../../../interfaces/apAgingReportInterface";
import { BATCH_SIZE } from "../../../../utility/constants";
import { SelectedClientContext } from "../../../../context/selectedClientContext";
import { IARCollateral, IBBPeriod, IClient } from "../../../../interfaces";
import { ICurrency, IRate } from "../../../../interfaces/multiCurrencyInterface";
import { isSelectedClientUPC } from "../main-container";
import { exportAPAgingReport } from "../../../../api/reports/ap-aging";
import { IExportReportWithPayload } from "../../../../interfaces/exportReportInterface";
import { ExportReportContext } from "../../../../context/exportReportContext";

export interface IAPAgingReportPageHeaderProps {
  selectedExchangeRate: IRate | null;
}

const APAgingReportPageHeader: React.FC<IAPAgingReportPageHeaderProps> = (props) => {
  const { selectedExchangeRate } = props;
  const { selectedClient } = useContext(SelectedClientContext);
  const { selectedCollateral, selectedAsOfDate, selectedType, selectedCurrency, isLoading, hasGeneratedReport, arCollaterals, exchangeRates, sortParams, permissions } = useContext(APAgingReportContext) as IAPAgingReportContext;
  const { setShow, exports, setExports } = useContext(ExportReportContext);

  const [isExporting, setIsExporting] = useState<boolean>(false);

  const navigate                   = useNavigate();
  const theme                      = useTheme();
  const handleNavigate             = useCallback(() => navigate('/reports'), []);
  const belowMediumBreakpoint      = useMediaQuery(theme.breakpoints.down('md'));

  const getPayloadData = () => {
    const sortableHeaders = ['arVendorSrcId', 'arVendorName', 'total'];
    const sortProperties = sortableHeaders.includes(sortParams.orderBy)
      ? { order: sortParams.order.toUpperCase(), orderBy: sortParams.orderBy }
      : { order: 'ASC', orderBy: 'arVendorName' };
    const payload: IAPAgingReportExportPayload = {
      borrowerId: (selectedClient as IClient).recordId as number,
      arCollateralId: (selectedCollateral as IARCollateral).recordId,
      bbPeriodId: (selectedAsOfDate as IBBPeriod).recordId as number,
      bucketType: toCamelCase(selectedType as IBucketType),
      ...sortProperties,
      batchSize: BATCH_SIZE,
      currencyId: (selectedCurrency as ICurrency).recordId,
      ...(selectedExchangeRate !== null && { rateId: selectedExchangeRate.recordId } ),
    };
    return payload;
  };

  const getUPCPayloadData = (filename: string) => {
    const sortableHeaders = ['arVendorSrcId', 'arVendorName', 'total'];
    const sortProperties = sortableHeaders.includes(sortParams.orderBy)
      ? { order: sortParams.order.toUpperCase(), orderBy: sortParams.orderBy }
      : { order: 'ASC', orderBy: 'arVendorName' };
    const formattedEndDate = (selectedAsOfDate as IBBPeriod).endDate.replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');
    const payload: IUPCAPAgingReportExportPayload = {
      borrowerId: (selectedClient as IClient).recordId as number,
      endDate: formattedEndDate,
      bucketType: toCamelCase(selectedType as IBucketType),
      currencyId: (selectedCurrency as ICurrency).recordId,
      rateIdByARCollateralId: arCollaterals.reduce((rateIdBySetting, arSetting) => {
        const exchangeRate = exchangeRates.find(exchangeRate => exchangeRate.toCurrencyId.toString() === arSetting.arBorrowerInput.currency);
        const arCollateralId = arSetting.arCollateral.recordId;
        if (exchangeRate === undefined) { return { ...rateIdBySetting, [ arCollateralId ]: 1.0 }; }
        return { ...rateIdBySetting, [ arCollateralId ]: exchangeRate.currencyRate };
      }, {} as { [x: string]: number; }),
      filename,
      ...sortProperties
    };
    return payload;
  };

  const handleSelect = async (filetype: 'Excel' | 'PDF') => {
    try {
      if (isSelectedClientUPC(selectedClient)) { await exportUPCReport(filetype); return; }
      await exportReport(filetype);
    }
    catch (error) { console.log('EXPORT ERROR : ', error); }
    finally { setIsExporting(false); }
  };

  const exportReport = async (filetype: 'Excel' | 'PDF') => {
    setIsExporting(true);
      const exportResponse = await exportAPAgingReport(filetype, getPayloadData());
      let exportData = exportResponse.data;
      const url = window.URL.createObjectURL(new Blob([exportData]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        getFileName(
          filetype, 'APAging',
          formatDate((selectedAsOfDate as IBBPeriod).endDate ?? '', 'MM/DD/YYYY'),
          (selectedClient as IClient).borrowerName,
          (selectedCollateral as IARCollateral).arCollateralName
        )
      );
      document.body.appendChild(link);
      link.click();
  };

  const exportUPCReport = async (filetype: 'Excel' | 'PDF') => {
    const fileName = getFileName(
      filetype, 'APAging',
      formatDate((selectedAsOfDate as IBBPeriod).endDate ?? '', 'MM/DD/YYYY'),
      (selectedClient as IClient).borrowerName
    );
    const fileNameToExport: string = checkFileNameIfExisting(exports, fileName, 'APAging');
    const additionalPayload = {
      setExporting: setIsExporting,
      apiURL: reportsAPI.apAgingReport[`EXPORT_UPC_${filetype.toUpperCase()}`],
      payloadData: getUPCPayloadData(fileNameToExport)
    } as any;
    const fileToExport: IExportReportWithPayload = {
      filename: fileNameToExport,
      status: 'loading',
      originalFileName: fileName,
      ...additionalPayload,
    };
    setExports([...exports, fileToExport]);
    setShow(true);
  };

  const getExportButton = () => {
    if (!permissions?.exportAPAgingReport) { return <></>; }
    return (
      <MenuButton
        label="Export"
        options={[
          {label: 'Excel', handleSelect: () => handleSelect('Excel')},
          {label: 'PDF', handleSelect: () => handleSelect('PDF')}
        ]}
        buttonProps={{
          endIcon: isExporting ? <CircularProgress size={15} /> : <FileDownloadOutlinedIcon />,
          size: 'medium',
          variant: 'outlined',
          disabled: !hasGeneratedReport || isLoading || isExporting,
          'aria-label': 'Export icon',
          sx: styles.headerButtons
        }}
      />
    );
  };

  return (
    <Box sx={styles.titleContainer}>
      <Typography tabIndex={0} variant='h6' component='h3' sx={styles.title}>
        AP Aging Report
      </Typography>
      <Box sx={styles.headerActionWrapper}>
        { getExportButton() }
        <Button
          size='medium'
          variant='outlined'
          aria-label='Go back icon'
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={handleNavigate}
          sx={styles.headerButtons}
        >
          { belowMediumBreakpoint ? null : 'Go back' }
        </Button>
      </Box>
    </Box>
  )
};

export default APAgingReportPageHeader;

import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import styles from './styles';

const Footer: FC = () => {
  return (
      <Box
        sx={styles.footerContainer}
      >
        <Typography tabIndex={0} sx={styles.footerVersion}> v0.4.24 </Typography>
      </Box>
  );
};

export default Footer;